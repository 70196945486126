import React from "react"
import { StaticQuery, graphql } from "gatsby"
import NavHolder from "./nav"

const HEADER_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const Header = props => (
  <StaticQuery
    query={HEADER_QUERY}
    render={data => {
      return (
        <div id='masthead' className='site-header'>
          <NavHolder />

          <div className='site-branding-container'>
            <div className='site-branding'></div>
            {/* .site-branding */}
          </div>
          {/* .layout-wrap */}
        </div>
      )
    }}
  />
)

export default Header
