import React, { useState, useLayoutEffect } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { useSpring, animated } from "react-spring";
import ILogo from "./icons/logo";
import Menu from "./menu";
import { Location } from "@reach/router";

const NavHolder = props => {

  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  function useLockBodyScroll() {
    useLayoutEffect(() => {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
  }
  function Navmenu({ path, location }) {
    // Call hook to lock body scroll
    useLockBodyScroll();

    return (
      <animated.div className={`nav-wrapper ${path}`} style={menuAnimation}>
        <Menu
          menulink=""
          outerclass={"navbar-nav"}
          locationpath={location.pathname}
        />
      </animated.div>
    );
  }

  const menuAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: collapsed ? 1 : 0,
      transform: collapsed
        ? "translate3d(0,0,0) scale(1)"
        : "translate3d(0,-1%,0) scale(1)"
    },
    config: { duration: 500 }
  });

  // Hamburger Animation
  const openedTransformationConfig = {
    top: "translate(2, 7) rotate(0)",
    center: "translate(2, 19) rotate(0)",
    bottom: "translate(2, 31) rotate(0)",
    color: "#0076A8" // Add color
  };
  const closedTransformationConfig = {
    top: "translate(5, 32) rotate(-45)",
    center: "translate(9, 4) rotate(45)",
    bottom: "translate(5, 32) rotate(-45)",
    color: "#0076A8" // Add color
  };
  const { top, center, bottom, color } = useSpring({
    to: collapsed ? closedTransformationConfig : openedTransformationConfig
  });

  return (
    <Location>
      {({ location }) => {
        const path = location.pathname.split("/")[1].trim();

        return (
          <Navbar color="faded" light>
            <div className="container | px-0">
              <NavbarBrand href="/" className="mr-auto ilogo">
                <ILogo />
              </NavbarBrand>

              <animated.svg
                onClick={toggleNavbar}
                className="menu-button"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill={color}
                xmlns="http://www.w3.org/2000/svg"
              >
                <animated.rect width="40" height="5" rx="0" transform={top} />
                <animated.rect
                  width="40"
                  height="5"
                  rx="0"
                  transform={center}
                />
                <animated.rect
                  width="26"
                  height="5"
                  rx="0"
                  transform={bottom}
                />
              </animated.svg>

              {collapsed && <Navmenu path={path} location={location} />}
            </div>
          </Navbar>
        );
      }}
    </Location>
  );
};

export default NavHolder;
