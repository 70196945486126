import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SvgIdealCapitalGroupLogoFooter from "./icons/logos/IdealCapitalGroupLogoFooter";
import FooterMenu from "./footermenu";
import { Container, Row, Col } from "reactstrap";

const FOOTER_QUERY = graphql`
  query SiteFooterQuery {
    site {
      siteMetadata {
        address
        city
        state
        zip
        phoneFormat
        phone
      }
    }
  }
`;
const Footer = props => (
  <StaticQuery
    query={FOOTER_QUERY}
    render={data => {
      return (
        <footer id="colophon" className="site-footer">
          <div className="footer-arrow-container"></div>
          <Container>
            <Row className="footer__content">
              <Col
                sm={{ size: 4, offset: 1 }}
                md={{ size: 7 }}
                lg={{ size: 5 }}
              >
                <FooterMenu
                  outerclass={"navbar footer__nav"}
                  innerclass={"footer__nav-item"}
                  menulink={"footer__nav-link"}
                />
              </Col>
              <Col lg={{ size: 2, offset: 1 }} className="d-none d-lg-block footer__logo">
                <SvgIdealCapitalGroupLogoFooter />
              </Col>
              <Col sm={{ size: 6, offset: 1 }} md={{ size: 4 }} className="footer__logo">
                <SvgIdealCapitalGroupLogoFooter className="d-lg-none" />
                <p className="footer__address">
                  {data.site.siteMetadata.address} {data.site.siteMetadata.city}
                  , {data.site.siteMetadata.state} {data.site.siteMetadata.zip}
                </p>
                <a
                  className="footer__phone"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phoneFormat}
                </a>
              </Col>
            </Row>
          </Container>
          <a className="footer__byline" href="https://primary360.com" target="_blank" rel="noopener noreferrer">Website by PRIMARY</a>
        </footer>
      );
    }}
  />
);

export default Footer;
