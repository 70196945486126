import React from "react";
import { StaticQuery, graphql } from "gatsby"
import { Nav, NavItem, NavLink } from 'reactstrap';
import { animated, useSpring } from 'react-spring';

const MENU_QUERY = graphql`
  query GET_MENU_ITEMS {
    site {
        siteMetadata {
          wordPressUrl
        }
      }
    wpgraphql {
      menuItems(where: { location: MENU_1 }) {
        nodes {
          id
          label
          url
          connectedObject {
            __typename
          }
        }
      }
    }
  }
`

const createLocalLink = (url, wpUrl) => {
  if (`#` === url) {
    return null
  }
  return url.replace(wpUrl, ``)
}
const createActiveClass = (link, locate) => {
  const subpath = locate.split('/')[1]
  if (`/${subpath}/` === link) {
    return ' is-active'
  } else if (locate === link) {
    return ' is-active'
  }
  return ''
}


const renderMenuItem = (menuItem, wpUrl, inner, mlink, locate, i) => {

  const link = createLocalLink(menuItem.url, wpUrl)
  const classActive = createActiveClass(link, locate)
  return (

    
    <NavItem className={inner}>
      <NavLink
        key={menuItem.id}
        href={link}
        className={`Nav-item${classActive} ${mlink}`}>
        {menuItem.label} </NavLink>
    </NavItem>
  )
}

const Menu = (props) => {
  const [arrowprops, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 7]
  const trans1 = (x, y) => `translate3d(0px,${y}px,0)`

  return (
    <StaticQuery
      query={MENU_QUERY}
      render={({ wpgraphql, site }) => {
        const wpUrl = site.siteMetadata.wordPressUrl
        const inner = props.innerclass
        const mlink = props.menulink
        const locate = props.locationpath

        return (
          <Nav className={props.outerclass} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
            <animated.div class="menu-arrow" style={{ transform: arrowprops.xy.interpolate(trans1) }} />
            {wpgraphql.menuItems.nodes.filter(menuItem => locate !== undefined).map(menuItem => {
              return renderMenuItem(menuItem, wpUrl, inner, mlink, locate)
            })}
          </Nav>
        )
      }}
    />
  );
};

export default Menu
