import React from "react";

const SvgIdealCapitalGroupLogoFooter = props => (
  <svg viewBox="0 0 206 66.9" {...props}>
    <path
      d="M38.2 63.2c0-2.1 1.5-3.8 3.7-3.8 1.3 0 2.1.5 2.8 1.1l-.7 1c-.6-.5-1.2-.9-2-.9-1.4 0-2.4 1.1-2.4 2.6 0 1.4 1 2.6 2.4 2.6.9 0 1.5-.4 2.1-.9l.8.8c-.8.8-1.6 1.3-2.9 1.3-2.2-.1-3.8-1.7-3.8-3.8zm13.1-3.7h1.2l3.2 7.3h-1.3l-.7-1.8h-3.4l-.7 1.8h-1.3l3-7.3zm1.9 4.4L51.9 61l-1.2 2.9h2.5zm6.3-4.3h2.9c1.7 0 2.8 1 2.8 2.4 0 1.6-1.3 2.5-2.9 2.5h-1.4v2.3h-1.3l-.1-7.2zm2.8 3.7c1 0 1.6-.5 1.6-1.3s-.6-1.3-1.6-1.3h-1.5v2.6h1.5zm6.8-3.7h1.3v7.3h-1.3v-7.3zm7.6 1.1h-2.3v-1.2h5.9v1.2H78v6.1h-1.3v-6.1zm9.2-1.2h1.2l3.2 7.3H89l-.7-1.8h-3.4l-.9 1.8h-1.3l3.2-7.3zm1.8 4.4L86.4 61l-1.2 2.9h2.5zm6.4-4.3h1.3v6.1h3.8v1.2h-5.1v-7.3zm14.4 3.6c0-2 1.6-3.8 3.7-3.8 1.3 0 2 .4 2.8 1l-.8 1c-.6-.5-1.1-.8-2-.8-1.3 0-2.4 1.2-2.4 2.6 0 1.5 1 2.6 2.5 2.6.7 0 1.3-.2 1.8-.5v-1.4h-1.9v-1.1h3.1v3c-.7.6-1.7 1.1-3 1.1-2.3 0-3.8-1.6-3.8-3.7zm11-3.6h3.2c.9 0 1.6.3 2.1.7.4.4.6.9.6 1.6 0 1.2-.7 1.9-1.7 2.2l2 2.7h-1.5l-1.8-2.5h-1.6v2.5h-1.3v-7.2zm3.2 3.6c.9 0 1.5-.5 1.5-1.2 0-.8-.6-1.2-1.5-1.2h-1.9v2.4h1.9zm6.6 0c0-2.1 1.6-3.8 3.8-3.8 2.2 0 3.8 1.7 3.8 3.7s-1.6 3.8-3.8 3.8c-2.3 0-3.8-1.6-3.8-3.7zm6.2 0c0-1.4-1-2.6-2.5-2.6-1.4 0-2.4 1.1-2.4 2.6 0 1.4 1 2.6 2.5 2.6 1.4 0 2.4-1.2 2.4-2.6zm5.4.5v-4.2h1.3v4.1c0 1.3.7 2.1 1.8 2.1s1.8-.7 1.8-2v-4.2h1.3v4.1c0 2.2-1.2 3.3-3.1 3.3-1.9 0-3.1-1.1-3.1-3.2zm10.5-4.1h2.9c1.7 0 2.8 1 2.8 2.4 0 1.6-1.3 2.5-2.9 2.5h-1.4v2.3h-1.3l-.1-7.2zm2.8 3.7c1 0 1.6-.5 1.6-1.3s-.6-1.3-1.6-1.3h-1.5v2.6h1.5zM85.8 31.5v10.3l21.7-.7v-6.8z"
      fill="#8DC8E8"
    />
    <path fill="#73AFCA" d="M79.7 35.4l6.1-3.9v10.3l-6.1-1.5z" />
    <path fill="#004F77" d="M79.7 25.6l6.1-9.6v9.8l-6.1 4.7z" />
    <path fill="#006292" d="M98.2 19.4L85.8 16v9.8l12.4 2.3z" />
    <path fill="#00426A" d="M107.5 10.3L85.8 0v9.9l21.7 7.6z" />
    <path fill="#00304D" d="M79.7 15.1L85.8 0v9.9l-6.1 10.2z" />
    <path
      fill="#00426A"
      d="M0 4.4h6.6v37.4H0V4.4zm206 31.3h-19.2V4.4h-6.6v37.3h23.4zM43.8 4.4H29.9v37.4h13.9c11.7 0 19.9-8.2 19.9-18.7V23c0-10.6-8.1-18.6-19.9-18.6zm13 18.7c0 7.3-5.1 12.6-13 12.6h-9.5l2.1-1.4v-24h7.4c7.8 0 13 5.4 13 12.7v.1zm93.9 7.1l4.8 11.5h6.9L146 4.1h-6.1l-16.4 37.6h6.7l3.8-9h15.7l1-2.5zm-15.9-3.3l2.2-1.5 5.8-13.5 6.5 14.9-14.5.1z"
    />
  </svg>
);

export default SvgIdealCapitalGroupLogoFooter;
